import React from 'react';
import './CartItem.scss';
import {TrashIcon} from '@heroicons/react/20/solid';
import Button from '../../../components/Button/Button';
import {useDispatch} from 'react-redux';
import {removeItem, updateItem} from '../../../redux/cart.reducer';

const CartItem = ({qty, product}) => {

  const dispatch = useDispatch();

  const updateQuantity = (qty) => dispatch(updateItem({item: product, qty}));

  const getLabel = () => {
    const variantIndex = product.id.split('__')[1];
    if (variantIndex) {
      return product.variants[variantIndex].label;
    }
    return undefined;
  };

  return (
      <>
        <li className="cart-item">
          <div className="image-section">
            <img src={product.thumbnail.url} alt={product.thumbnail.label} className="image"/>
          </div>
          <div className="info-section">
            <div className="name">{product.name}</div>
            <div className="category">{product.categoryName}</div>
            <div className="variant">{getLabel()}</div>
            <div className="action-bar">
              <div className="label">Quantity:</div>
              <div className="value action-bar controls">
                {/*<button className={'control minus'} onClick={() => {*/}
                {/*  updateQuantity(qty - 1);*/}
                {/*}}>*/}
                {/*  <MinusIcon className={'heroicon-20'}/>*/}
                {/*</button>*/}
                <input type="number" min={1} max={100} className={'input-value'} value={qty}
                       onChange={(e) => {
                         updateQuantity(+e.target.value);
                       }}/>
                {/*<button className={'control add'} onClick={() => {*/}
                {/*  updateQuantity(qty + 1);*/}
                {/*}}>*/}
                {/*  <PlusIcon className={'heroicon-20'}/>*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
          <div className="action-section">
            <Button className={'icon-only accent'} aria-label={'Remove Item'} onClick={() => {
              dispatch(removeItem(product));
            }}>
              <TrashIcon className={'heroicon-20'}/>
            </Button>
          </div>
        </li>
      </>
  );
};

export default CartItem;
