import React, {useEffect, useState} from 'react';
import './ProductsCategoryList.scss';
import Loading from './common/Loading/Loading';
import ProductCategoryCard from './Product-Category-Card/ProductCategoryCard';
import {useDispatch, useSelector} from 'react-redux';
import {getAllMetaCategories} from '../firebase/meta-categories.store';
import {setAllMetaCategories} from '../redux/data.reducer';

const ProductsCategoryList = () => {
  const [loading, setLoading] = useState(true);

  const categoryList = useSelector(state => state.data.metaCategories.payload) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    if (categoryList?.length) {
      setLoading(false);
      return;
    }

    const metaCategories = await getAllMetaCategories();
    dispatch(setAllMetaCategories(metaCategories));
    setLoading(false);
  };

  return (
      <>
        {
          loading ? <Loading padding={'3rem'} title={'Fetching Products...'}/> :
              <>
                <ul className="category-list">
                  {
                    categoryList?.length ?
                        <>
                          {
                            categoryList.map((value, index) =>
                                <li key={`${value.label}-${index}`} className={'category-list_item'}>
                                  <ProductCategoryCard metaCategory={value}/>
                                </li>)
                          }
                        </> : undefined
                  }
                </ul>
              </>
        }
      </>
  );
};

export default ProductsCategoryList;
