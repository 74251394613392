export const LINKS = [
  {
	to: '/',
	end: true,
	label: 'Home'
  },
  {
	to: '/services',
	end: false,
	label: 'Services'
  },
  {
	to: '/products',
	end: false,
	label: 'Products'
  },
  {
	to: '/contact',
	end: false,
	label: 'Contact'
  }
];

export const TOP_LINKS = [
	{
		to: '/products',
		end: false,
		label: 'Products'
	},
	{
		to: '/contact',
		end: false,
		label: 'Contact'
	}
];


export const COMPANY_LOCATION = 'https://www.google.com/maps/place/Weight-Tech+Solutions/@43.7158909,-79.8456936,17z/data=!3m1!4b1!4m6!3m5!1s0x4d0953ac09f528cd:0x6d36e1542e68a648!8m2!3d43.7158871!4d-79.8431187!16s%2Fg%2F11txpq96yh';
