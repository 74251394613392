import React, {useState} from 'react';
import './ImageGallery.scss';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid';

const ImageGallery = ({images, ...props}) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const updateImageIndex = (direction) => {
    selectImageIndex(currentImageIndex + direction);
  };

  const selectImageIndex = (index) => {
    setCurrentImageIndex(index);
  };

  return (
      <>

        <div className="image-gallery">
          <div className="image-gallery_main-image">
            <img
                src={images[currentImageIndex].url}
                alt={images[currentImageIndex].alt}/>
          </div>
          <div className="horizontal-divider"></div>
          <div className="image-gallery_gallery">
            <button className="control left" disabled={currentImageIndex <= 0} onClick={() => updateImageIndex(-1)}>
              <ChevronLeftIcon className={'heroicon-24'}/>
            </button>
            <div className="image-gallery_gallery-images">
              {
                images.map((value, index) =>
                      <img
                          id={`image-${index}`}
                          src={value.url} key={value.url + '---' + index} onClick={() => selectImageIndex(index)}
                          alt={value.alt}
                          className={`image-gallery_thumbnail ${currentImageIndex === index && 'active'}`}/>
                )
              }
            </div>
            <button className="control right" disabled={currentImageIndex >= images.length - 1}
                    onClick={() => updateImageIndex(1)}>
              <ChevronRightIcon className={'heroicon-24'}/>
            </button>
          </div>
        </div>
      </>
  );
};

export default ImageGallery;
