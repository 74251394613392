import React from 'react';
import './DocumentCard.scss';
import {DocumentArrowDownIcon, DocumentIcon} from '@heroicons/react/24/solid';
import Button from '../Button/Button';
import {downloadFile} from '../../utils/file';
import {removeFileExtension} from '../../utils/common';

const DocumentCard = ({name, url, ctaText}) => {
  return (
      <>
        <div className="document-card">
          <div className="content">
            <DocumentIcon className={'heroicon-24'}/>
            <div className="name">{removeFileExtension(name)}</div>
            <div className="spacer"></div>
            <Button className={'outline accent'} onClick={() => downloadFile(name, url)}>
              {ctaText || 'Download'}
              <DocumentArrowDownIcon className={'heroicon-24'}/>
            </Button>
          </div>
        </div>
      </>
  );
};

export default DocumentCard;
