import {db} from './index';
import {collection, doc, getDoc, getDocs, query} from 'firebase/firestore';
import {snapShotToArray} from './common';

const metaCategoryCollection = collection(db, 'meta-categories');

export const getAllMetaCategories = async () => {
  const array = snapShotToArray(await getDocs(query(metaCategoryCollection)));

  return array.map(value => {
    const {updatedAt, ...other} = value;
    return other;
  });
};

export const getMetaCategory = async (id) => {
  return (await getDoc(doc(db, `meta-categories`, id))).data();
};
