import React from 'react';
import './HomePageSection.scss';

const HomePageSection = ({className, title, subTitle, headerSection, children, ...otherProps}) => {
  return (
      <>
        <section className={`home-page-section ${className}`}>
          {
              (title && subTitle) &&
              <div className="header">
                <div className="header_content">
                  {
                      title && <h2 className="title">{title}</h2>
                  }
                  {
                      subTitle && <h2 className="subTitle">{subTitle}</h2>
                  }
                  {
                      headerSection &&
                      <>
                        <span className="spacer"/>
                        {headerSection}
                      </>

                  }
                </div>
              </div>
          }
          <div className="content">
            {
              children
            }
          </div>
        </section>
      </>
  );
};

export default HomePageSection;
