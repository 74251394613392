import React, {useEffect} from 'react';
import './Sandbox.scss';
import {sendMessage} from '../../firebase/message.store';
import Button from '../../components/Button/Button';
import Page from '../../components/common/Page/Page';
import {useDispatch} from 'react-redux';
import {addCrumbs} from '../../redux/breadcrumb.reducer';

const Sandbox = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addCrumbs([{label: 'Sandbox', url: '/sandbox'}]));
  }, []);

  const product = {
    id: '2741e705-6b48-4515-9c23-2393e6a71ac9',
    name: 'WTX-1 Scale One 0 One',
    thumbnail: {
      url: 'https://firebasestorage.googleapis.com/v0/b/weigh-tech-solutions.appspot.com/o/images%2FBench%20scale%20light%20weight.jpg?alt=media&token=cbf5f051-6e11-4042-8a37-38e661103b93',
      label: 'Scale One 0 One'
    },
    categoryName: 'Industrial Scale',
    categoryId: '2352a0f5-8938-488a-a68a-c45e5bd8ff5d',

  };

  const sendContactEmail = async (type = 'contact', message = 'Hello World', data = {}) => {
    await sendMessage({
      to: {
        email: 'karmit199@gmail.com',
        name: 'Karmit Patel',
        phone: '+1-647-575-5240'
      },
      type,
      message: 'Hello World',
      data
    });
  };

  const sendServiceEmail = async () => {
    await sendContactEmail('service', 'This is a service email', {
      company: {name: 'Weigh Tech Solutions'},
      product: {model: 'WTS-1', brand: 'Weigh-Tech Solutions'},
      address: {street: '1 Street Street', city: 'Brampton', postalCode: 'A1A 1A1', country: 'Canada'}
    });
  };

  const sendProductEmail = async () => {
    await sendContactEmail('product', 'This is a product email', {
      products: [product, product, product, product]
    });
  };

  return (
      <>
        <Page title={'Sandbox'}>
          <Button className={'primary solid'} onClick={() => sendContactEmail()}>Send Contact Email</Button>
          <Button className={'outline primary'} onClick={() => sendServiceEmail()}>Send Service Email</Button>
          <Button className={'primary'} onClick={() => sendProductEmail()}>Send Product Email</Button>
        </Page>
      </>
  );
};

export default Sandbox;
