import React from 'react';
import './Loading.scss';
import {ThreeDots} from 'react-loader-spinner';

const Loading = ({title, subTitle, type = 'three-dots', padding = '1em'}) => {

  return (
      <>
        <div className="loading-component" style={{padding}}>
          <div className={'title'}>{title || 'Loading...'}</div>
          <ThreeDots visible={true}
                     height="80"
                     width="80"
                     color="#e31837"
                     radius="9"
                     ariaLabel="three-dots-loading"
                     wrapperStyle={{}}
                     wrapperClass=""
          />
          {
              subTitle && <div className={'subTitle'}>{subTitle}</div>
          }
        </div>
      </>
  );
};

export default Loading;
