import React from 'react';
import './Page.scss';

const Page = ({title, subTitle, className, hasMin, headerActions, children}) => {
  return (
      <>
        <section className={`page ${className} ${hasMin && 'has-min'}`}>
          {
              (title || subTitle) &&
              <header className={'header'}>
                <div className="header_content">
                  {
                      title && <h1 className="title">{title}</h1>
                  }
                  {
                      subTitle && <h2 className="subTitle">{subTitle}</h2>
                  }
                </div>
                <span className="spacer"></span>
                {
                    headerActions && headerActions
                }
              </header>
          }
          <section className="content">
            {
              children
            }
          </section>
        </section>
      </>
  );
};

export default Page;
