import React from 'react';
import './Button.scss';

const Button = ({className, children, isLink, ...otherProps}) => {
  return (
	  <>
		  <button className={`btn ${className ?? ''} ${isLink && 'link'}`} {...otherProps}>
		  {children}
		</button>
	  </>
  );
};


export default Button;
