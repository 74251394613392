// const fs = require('fs');

export const writeToFile = (fileName, data) => {
  // fs.writeFile(fileName, JSON.stringify(data), {encoding: 'utf-8'}, (e) => {
  //   console.log(e);
  // });
  
  const element = document.createElement('a');
  const textFile = new Blob([JSON.stringify(data)], {type: 'application/json'}); //pass data from localStorage API to blob
  element.href = URL.createObjectURL(textFile);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  element.remove();
};

export const downloadFile = async (name, url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.click();
  a.remove();
};
