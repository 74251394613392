import React, {useState} from 'react';
import './Hero.scss';
import Button from '../Button/Button';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import HeroImage from '../../pages/Home/hero-image.jpg';
import ContactImage from '../../pages/Home/contact.jpg';
import ServiceImage from '../../pages/Home/services-2.jpg';
import {HERO_CONTENT} from '../../constants/texts';
import {Carousel} from 'react-responsive-carousel';
import {Link} from 'react-router-dom';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid';

const Hero = () => {

  const [currentCarouselImage, setCurrentCarouselImage] = useState(0);

  const heroImages = [HeroImage, ServiceImage, ContactImage];

  const getImage = (index) => heroImages[index];

  return (
      <>
        <section className="home-hero">
          <Carousel axis={'horizontal'}
                    animationHandler={'slide'}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={5000}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={true}
                    showIndicators={true}
                    onChange={(index) => {
                      setCurrentCarouselImage(index);
                    }}
                    renderArrowNext={(clickHandler, hasNext, label) => hasNext &&
                        <div className="action action-right" onClick={clickHandler}>
                          <ChevronRightIcon className={'heroicon-24'}/>
                        </div>
                    }
                    renderArrowPrev={(clickHandler, hasPrev, label) => hasPrev &&
                        <div className="action action-left" onClick={clickHandler}>
                          <ChevronLeftIcon className={'heroicon-24'}/>
                        </div>
                    }
          >
            {
              HERO_CONTENT.map(({title, subTitle, cta: {href, label}}, index) =>
                  <section className="hero" key={`${index}-${title}`}>
                    <section className="text-content-section">
                      <div className="text-content-section_main">
                        <h2 className="preTitle">{title}</h2>
                        <h1 className="title" dangerouslySetInnerHTML={{__html: subTitle}}/>
                      </div>
                      <Link to={href} relative={'path'} className={'cta-button-link'}>
                        <Button className={'solid pill primary large'}>
                          {label}
                          <ArrowRightCircleIcon className={'heroicon-32 color-light'}/>
                        </Button>
                      </Link>

                    </section>
                    <section className="hero-image-section">
                      <img src={getImage(currentCarouselImage)} alt="Hero" className={'hero-image'}/>
                    </section>
                  </section>
              )
            }
          </Carousel>
        </section>
      </>
  );
};

export default Hero;
