import React from 'react';
import './ProductCard.scss';
import Button from '../Button/Button';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from 'react-router-dom';
import {ChevronDoubleRightIcon, ShoppingCartIcon} from '@heroicons/react/20/solid';
import {useDispatch} from 'react-redux';
import {addItem} from '../../redux/cart.reducer';

const ProductCard = ({product, loading, ...otherProps}) => {

  const dispatch = useDispatch();

  return (
      <>
        {
          loading ?
              <div className="product-card loading">
                <Skeleton className={'thumbnail'} style={{width: '100%', height: '350px'}}/>
                <div className="text-content">
                  <h2 className="title">
                    <Skeleton style={{width: '120px', height: '30px'}}/>
                  </h2>
                  <h3 className="category-name">
                    <Skeleton style={{width: '80px', height: '20px'}}/>
                  </h3>
                  <div className="cta">
                    <Skeleton style={{width: '100%', height: '30px'}}/>
                  </div>
                </div>
              </div>
              :
              <div className="product-card">
                <Link to={`/products/${product.categoryId}/${product.id}`}>
                  <img src={product.thumbnail.url} alt={product.thumbnail.name} className="thumbnail"/>
                </Link>
                <div className="text-content">
                  <Link to={`/products/${product.categoryId}/${product.id}`}
                        style={{display: 'flex', flex: '1 1 auto'}}>
                    <div className="meta-content">
                      <h2 className="title">{product.name}</h2>
                    </div>
                  </Link>
                  <div className="action-bar cta">
                    <Link to={`/products/${product.categoryId}/${product.id}`} className={'details-button'}>
                      <Button className={'outline accent'}>View Details <ChevronDoubleRightIcon
                          className={'heroicon-20'}/></Button>
                    </Link>
                    <Button className={'solid accent cart-button'} onClick={() => {
                      dispatch(addItem({item: product, qty: 1}));
                    }}>
                      <ShoppingCartIcon className={'heroicon-20'}/>
                    </Button>
                  </div>
                </div>
              </div>
        }

      </>
  );
};

export default ProductCard;
