import {combineReducers, configureStore} from '@reduxjs/toolkit';
import DataReducer from './data.reducer';
import NotificationReducer from './notification.reducer';
import CartReducer from './cart.reducer';
import BreadcrumbReducer from './breadcrumb.reducer';

export default configureStore({
  reducer: combineReducers({
    data: DataReducer,
    notification: NotificationReducer,
    cart: CartReducer,
    breadcrumbs: BreadcrumbReducer
  }),
});
