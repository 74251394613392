import React, {useRef} from 'react';
import './Footer.scss';
import CompanyLogo from '../Navbar/Company-Logo.svg';
import ASPImage from '../../assets/images/ASP.jpg';
import NTPImage from '../../assets/images/NTEP.jpg';
import {NavLink} from 'react-router-dom';
import {EnvelopeIcon, PaperAirplaneIcon, PhoneIcon} from '@heroicons/react/24/solid';
import {LINKS} from '../../constants/links';
import Button from '../Button/Button';
import {sendMessage} from '../../firebase/message.store';
import {showNotification} from '../../redux/notification.reducer';
import {useDispatch} from 'react-redux';

const Footer = () => {

  const nameRef = useRef();
  const emailRef = useRef();

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    if (name && email) {
      await sendMessage({
        to: {
          email,
          name,
        },
        type: 'contact',
        data: {}
      });

      dispatch(showNotification({
        message: 'Thank you for reaching out. Have a fantastic day! 😊',
        title: 'Message Received! We\'re on It!',
        type: 'success',
        duration: 5_000
      }));
    }

    e.target.reset();
  };

  return (
      <footer>
        <div className="footer-section company-data">
          <img src={CompanyLogo} alt="Company Logo" className="company-logo"/>
          <a className="line a-tag-link" href={'mailto:hiren@weightechsolutions.ca'}>
            <EnvelopeIcon className={'heroicon-24 color-primary'}/> hiren@weightechsolutions.ca
          </a>
          <div className="line">
            <a href={'tel:+1-437-460-9068'} className={'a-tag-link'}>
              <PhoneIcon className={'heroicon-24 color-primary'}/>+1-437-460-9068
            </a>
          </div>
          {/*<a target={'_blank'} href={COMPANY_LOCATION} rel={'noreferrer'} className={'inline a-tag-link'}>*/}
          {/*<MapPinIcon className={'heroicon-24 color-primary'}/>*/}
          {/*<div>*/}
          {/*  <span className='line'> 598 Edenbrook Hill Drive,</span>*/}
          {/*  <span className='line'> Brampton, Ontario, L7A 4T5</span>*/}
          {/*</div>*/}
          {/*</a>*/}
        </div>
        <div className="footer-section links-section">
          <div className="title">Links</div>
          <ul className="links">
            {
              LINKS.map(({to, end, label}, index) =>
                  <NavLink
                      className={({isActive}) => `${isActive ? 'active' : ''} link`} to={to} end={end}
                      key={`${index}-${to}`}
                  >
                    {label}
                  </NavLink>
              )
            }
          </ul>
        </div>
        <div className="footer-section accredition">
          <div className="title">Accreditions</div>
          <div className="data">
            <div className="subTitle">Ensuring precision and compliance in all our measurement standards, certified by
              Canada's leading authority.
            </div>
            <div className="images">
              <img src={NTPImage} alt="NTEP Image" className={'accredition-image'}/>
              <img src={ASPImage} alt="NTEP Image" className={'accredition-image'}/>
            </div>
          </div>
        </div>
        <div className="footer-section contact-forms">
          <div className="title">
            Contact Us
          </div>
          <form onSubmit={handleSubmit}>
            <fieldset name={'name'}>
              <label htmlFor="name">Name</label>
              <input
                  type="text" name="name" id="name" placeholder={'Enter your name'}
                  autoComplete={'given-name family-name'}
                  required={true}
                  ref={nameRef}
              />
            </fieldset>
            <fieldset name={'email'}>
              <label htmlFor="email">Email</label>
              <input
                  type="email" name="email" id="email" placeholder={'Enter your email'} autoComplete={'email'}
                  required={true}
                  ref={emailRef}
              />
            </fieldset>
            <Button className={'solid primary full'} type={'submit'}>
              Send Message
              <PaperAirplaneIcon className={'heroicon-24'}/>
            </Button>
          </form>
        </div>
      </footer>
  );
};

export default Footer;
