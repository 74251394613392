import React from 'react';
import './NoData.scss';
import {ReactComponent as NoDataSvg} from './no_data.svg';


const NoData = ({title, topSubtitle, bottomSubtitle, height = '480px'}) => {
  return (
      <>
        <section className="no-data">
          {title && <h1>{title}</h1>}
          <NoDataSvg style={{height}}/>
        </section>
      </>
  );
};

export default NoData;
