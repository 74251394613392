import React, {useEffect, useState} from 'react';
import './ProductDetailsPage.scss';
import {useParams} from 'react-router-dom';
import {getProduct} from '../../firebase/products.store';
import ProductDetails from '../../components/Product-Details/ProductDetails';
import {useDispatch} from 'react-redux';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import Loading from '../../components/common/Loading/Loading';

const ProductDetailsPage = () => {

  const params = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    fetchData(params.pId);
  }, [params]);

  const fetchData = async (pID) => {
    getProduct(pID)
        .then((value) => {
          setProduct(value);
          dispatch(addCrumbs([{label: 'Products', link: '/products'}, {
            label: value.categoryName,
            link: `/products/${params.cId}`
          }, {label: value.name, link: `/products/${params.cId}/${params.pId}`}]));
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
      <>
        {
          loading ?
              <><Loading title={'Loading Product'} subTitle={'Please wait while we load your product'}/></> :
              <>
                {
                  product.id ?
                      <ProductDetails product={product}/> : <>Product Not Found</>
                }
              </>
        }
      </>
  );
};

export default ProductDetailsPage;
