import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import {Provider} from 'react-redux';
import store from './redux';
import ProductDetailsPage from './pages/ProductDetailsPage/ProductDetailsPage';
import ProductsListPage from './pages/ProductsListPage/ProductsListPage';
import Sandbox from './pages/Sandbox/Sandbox';
import Checkout from './pages/Checkout/Checkout';
import ProductsCategoryPage from './pages/ProductsCategoryPage/ProductsCategoryPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: '',
        element: <Home/>
      },
      {
        path: 'services',
        element: <Services/>
      },
      {
        path: 'contact',
        element: <Contact/>
      },
      {
        path: 'contact/:id',
        element: <Contact/>
      },
      {
        path: 'contact/:id/:pId',
        element: <Contact/>
      },
      {
        path: 'products',
        element: <ProductsCategoryPage/>
      },
      {
        path: 'products/:cId',
        element: <ProductsListPage/>
      },
      {
        path: 'products/:cId/:pId',
        element: <ProductDetailsPage/>
      },
      {
        path: 'sandbox',
        element: <Sandbox/>
      },
      {
        path: 'checkout',
        element: <Checkout/>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router}/>
      </Provider>
    </React.StrictMode>
);
