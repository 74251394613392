import React from 'react';
import './DocumentList.scss';
import DocumentCard from '../DocumentCard/DocumentCard';

const DocumentList = ({priceSheets, manuals}) => {

  const renderPriceSheets = () => priceSheets?.length && priceSheets.map((value, index) =>
      <DocumentCard name={value.name} url={value.url} ctaText={'Download Price Sheet'}
                    key={`${value.name}-${index}`}/>
  );

  const renderManuals = () => manuals?.length && manuals.map((value, index) =>
      <DocumentCard name={value.name} url={value.url} ctaText={'Download Manuals'}
                    key={`${value.name}-${index}`}/>
  );

  return (
      <>
        <div className="document-list">
          {
            renderPriceSheets()
          }
          {
            renderManuals()
          }
        </div>
      </>
  );
};

export default DocumentList;
