import React, {useEffect, useState} from 'react';
import './ProductDetails.scss';
import Button from '../Button/Button';
import {ArrowLeftIcon, MinusIcon, PlusIcon} from '@heroicons/react/20/solid';
import ImageGallery from '../Image-Gallery/ImageGallery';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab/Tab';
import DocumentList from '../Document-List/DocumentList';
import {useDispatch, useSelector} from 'react-redux';
import {addItem} from '../../redux/cart.reducer';
import {ShoppingCartIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';
import Loading from '../common/Loading/Loading';
import Page from '../common/Page/Page';

const ProductDetails = (props) => {

  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [productQuantity, setProductQuantity] = useState(1);
  const cartItems = useSelector(state => state.cart.items);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setProduct(props.product);
    setLoading(false);
  }, []);

  useEffect(() => {
  }, [cartItems]);

  const hasProductInformationOrAssets = () => (product.information?.images?.length || product.information?.html?.length) && (product.assets?.manuals?.length || product.assets?.priceSheets?.length);

  const addItemToCart = () => {
    const {updatedAt, createdAt, ...productToAdd} = product;
    dispatch(addItem({
      qty: productQuantity,
      item: {...productToAdd, id: isVariantSelected() ? product.variants[selectedVariantIndex].id : productToAdd.id}
    }));

    setProductQuantity(1);
  };

  const isVariantSelected = () => selectedVariantIndex >= 0;

  const selectVariant = (index = -1) => {
    setSelectedVariantIndex(index);
  };

  return (
      <>
        <Page>
          {
            loading ? <>
              <Loading title={'Loading Product...'} subTitle={'Please wait while we load your product.'}/>
            </> : <section className="product-details-page">
              <header className="action-bar page-header vertical">
                <Link to={'..'} relative={'path'}>
                  <Button isLink={true}>
                    <ArrowLeftIcon className={'heroicon-20'}/> View All Products
                  </Button>
                </Link>
              </header>
              <section className="page-details">
                <article className="product-main">
                  <ImageGallery images={product.assets.images}/>
                  <div className="product-data">
                    <div className="action-bar vertical">
                      <h2 className="page-title">{product.name}</h2>
                      <h4 className="page-subtitle">{product.categoryName}</h4>
                    </div>
                    <div className="product-description"
                         dangerouslySetInnerHTML={{__html: product.shortDescription}}>
                    </div>
                    <div className="product-data-table">
                      {
                          product.meta?.model &&
                          <>
                            <div className="label">Model:</div>
                            <div
                                className="value">{isVariantSelected() ? product.variants[selectedVariantIndex].meta?.model : product.meta?.model}</div>
                          </>
                      }
                      {
                          product.meta?.brand &&
                          <>
                            <div className="label">Brand:</div>
                            <div
                                className="value">{isVariantSelected() ? product.variants[selectedVariantIndex].meta?.brand : product.meta?.brand}</div>
                          </>
                      }
                      {
                        (isVariantSelected() ? product.variants[selectedVariantIndex].price : product.price) ?
                            <>
                              <div className="label">Price:</div>
                              <div className="value">CAD
                                ${isVariantSelected() ? product.variants[selectedVariantIndex].price?.toFixed(2) : product.price.toFixed(2)}</div>
                            </>
                            : undefined
                      }
                      <div className="label">Quantity:</div>
                      <div className="value action-bar controls">
                        <button className={'control minus'} onClick={() => {
                          setProductQuantity(prevState => prevState <= 1 ? prevState : --prevState);
                        }}>
                          <MinusIcon className={'heroicon-20'}/>
                        </button>
                        <input type="number" min={1} max={100} className={'input-value'} value={productQuantity}
                               onChange={(e) => {
                                 setProductQuantity(+e.target.value);
                               }}/>
                        <button className={'control add'} onClick={() => {
                          setProductQuantity(prevState => ++prevState);
                        }}>
                          <PlusIcon className={'heroicon-20'}/>
                        </button>
                      </div>
                    </div>
                    {
                        product.variants?.length &&

                        <div>
                          <h3 className="subTitle">Select a Product Type</h3>
                          <ul className="product-variants">
                            <li className={`product-variant_selector ${selectedVariantIndex === -1 ? 'selected' : ''}`}
                                onClick={() => selectVariant()}>
                              No Variant
                            </li>
                            {
                              product.variants?.map((value, index) =>
                                  <li key={`${value.label}-${index}`}
                                      className={`product-variant_selector ${selectedVariantIndex === index ? 'selected' : ''}`}
                                      onClick={() => selectVariant(index)}>
                                    {value.label}
                                  </li>)
                            }
                          </ul>
                        </div>
                    }
                    <div className="action-bar">
                      <Button className={'primary solid add-to-cart-button'} onClick={addItemToCart}>
                        Add to Cart
                        <ShoppingCartIcon className={'heroicon-20'}/>
                      </Button>
                    </div>
                  </div>
                </article>
                {
                    hasProductInformationOrAssets() &&
                    <>
                      <div className="horizontal-divider"></div>
                      <div className="product-meta">
                        <Tabs>
                          {
                              (product.information?.images?.length || product.information?.html?.length) &&
                              <Tab label={'Product Details'}>
                                <div className={'product-details'}>
                                  {
                                    product.information.images
                                        .map((value, index) => <img src={value.url} alt={value.name}
                                                                    key={`${value.name}-index`}
                                                                    className={'product-details__image'}/>)
                                  }
                                </div>
                              </Tab>
                          }
                          {
                              (product.assets?.manuals?.length || product.assets?.priceSheets?.length) &&
                              <Tab label={'Documents'}>
                                <DocumentList priceSheets={product.assets.priceSheets}
                                              manuals={product.assets.manuals}/>
                              </Tab>
                          }
                        </Tabs>
                      </div>
                    </>
                }

              </section>
            </section>
          }
        </Page>
      </>
  );
};

export default ProductDetails;
