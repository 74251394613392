import React from 'react';
import './Home.scss';
import ServicesSection from '../../components/Services-Section/Services-Section';
import Hero from '../../components/Hero/Hero';
import {useScrollToTop} from '../../utils/scroll';
import Brands from '../../components/Brands/Brands';
import FeaturedProducts from '../../components/Featured-Products/FeaturedProducts';
import ProductsCategoryList from '../../components/ProductsCategoryList';
import HomePageSection from '../../components/common/Home-Page-Section/HomePageSection';

const Home = () => {

  useScrollToTop();

  return (
      <>
        <section className="home">
          <Hero/>
          <HomePageSection title={'Featured Products'}
                           subTitle={'Limited time availability, Don\'t miss out on these exceptional offerings...'}>
            <FeaturedProducts/>
          </HomePageSection>

          <HomePageSection title={'Brands We Serve'}
                           subTitle={'Discover the extensive range of brands we serve, offering over 40,000 parts and products!'}>
            <Brands/>
          </HomePageSection>

          <HomePageSection title={'Services We Offer'}
                           subTitle={'At Weigh-Tech Solutions, we offer a range of services.'}>
            <ServicesSection/>
          </HomePageSection>

          <HomePageSection title={'Our Products'}
                           subTitle={'Browse a wide range of our top of the line product!'}>
            <ProductsCategoryList/>
          </HomePageSection>
        </section>
      </>
  );
};

export default Home;
