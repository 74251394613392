import React from 'react';
import './Breadcrumbs.scss';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

const Breadcrumbs = () => {
  const breadcrumbs = useSelector((state) => state.breadcrumbs.crumbs) || [];
  return (
      <div aria-label="breadcrumb" className="breadcrumb-container">
        <ol className="breadcrumb">
          <li className={`breadcrumb-item`}>
            <Link to={`/`}>Home</Link>
          </li>
          {breadcrumbs && breadcrumbs.map((crumb, index) => (
              <li key={index} className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}>
                {crumb.link ? <Link to={crumb.link}>{crumb.label}</Link> : crumb.label}
              </li>
          ))}
        </ol>
      </div>
  );
};

export default Breadcrumbs;
