import React, {useEffect, useState} from 'react';
import './Services.scss';
import ServiceSection from './Services-Section/Service-Section';
import GetInTouch from '../../components/Get-In-Touch/Get-In-Touch';
import {useScrollToTop} from '../../utils/scroll';
import {getAllServices} from '../../firebase/services.store';
import {useDispatch, useSelector} from 'react-redux';
import {setAllServices} from '../../redux/data.reducer';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import Page from '../../components/common/Page/Page';

const Services = () => {

  const services = useSelector((state) => state.data.services?.payload || []);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addCrumbs([{label: 'Services', link: '/services'}]));
    fetchData();
  }, []);

  useScrollToTop();

  const fetchData = async () => {
    if (!services.length) {
      setLoading(true);
      dispatch(setAllServices(await getAllServices()));
    }
    setLoading(false);
  };

  return (
      <>
        <Page className={'services'} title={'Services'}>
          {
            loading ?
                <>
                  <ServiceSection loading={loading}/>
                  <ServiceSection loading={loading}/>
                  <ServiceSection loading={loading}/>
                  <ServiceSection loading={loading}/>
                  <ServiceSection loading={loading}/>
                  <ServiceSection loading={loading}/>
                </>
                :
                services.length && services.map((service, index) =>
                    <ServiceSection service={service} key={`${index}-${service.title}`}/>
                )
          }
        </Page>
        <div style={{width: '100%'}}>

          <GetInTouch/>
        </div>
      </>
  );
};

export default Services;
