import {createSlice} from '@reduxjs/toolkit';

export const BreadcrumbReducer = createSlice({
  name: 'notification',
  initialState: {
    crumbs: []
  },
  reducers: {
    addCrumbs: (state, action) => {
      state.crumbs = action.payload;
    },
    removeCrumbs: (state) => {
      state.crumbs = [];
    }
  }
});

export const {
  addCrumbs,
  removeCrumbs
} = BreadcrumbReducer.actions;

export default BreadcrumbReducer.reducer;
