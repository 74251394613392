import React, {useEffect, useRef, useState} from 'react';
import './ProductsListPage.scss';
import {getProductsForCategory} from '../../firebase/products.store';
import ProductCard from '../../components/Product-Card/Product-Card';
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';
import NoData from '../../components/NoData/NoData';
import {sanitizeText} from '../../utils/common';
import Loading from '../../components/common/Loading/Loading';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getMetaCategory} from '../../firebase/meta-categories.store';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import Page from '../../components/common/Page/Page';

const ProductsListPage = () => {

  const params = useParams();
  const categories = useSelector((state) => state.data.metaCategories.payload) || [];

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({});

  const searchRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [params]);

  const fetchData = async () => {
    if (productList?.length <= 0) {
      const newProductList = await getProductsForCategory(params.cId);
      setProductList(newProductList);
      setFilteredProducts(filterData(newProductList));
    } else {
      setFilteredProducts(filterData(productList));
    }

    let category = categories.find(value => value.id === params.cId);
    if (category) {
      setCategory(category);
    } else {
      category = await getMetaCategory(params.cId);
      if (category) {
        setCategory(category);
      }
    }

    dispatch(addCrumbs([{label: 'Products', link: '/products'}, {
      label: category.name,
      link: `/products/${params.cId}`
    }]));

    setLoading(false);

  };

  const search = () => {
    setFilteredProducts(filterData(productList));
  };


  const filterData = (data = productList) => [...data].filter(value => sanitizeText(value.name).includes(sanitizeText(searchRef.current.value)));

  return (
      <>
        <Page title={category.name} className={'product-list-page'}
              subTitle={'Products List'}
              headerActions={<div className="search-bar">
                <input type="text" name="search" id="search" onInput={search} ref={searchRef}
                       placeholder={'Search Products'}/>
                <MagnifyingGlassIcon className={'heroicon-24'}/>
              </div>}>
          {
            loading ?
                <Loading title={'Fetching Products'}
                         subTitle={'Please wait while we fetch our products!'}
                         padding={'5em 1em'}/> :
                <div className="content">
                  {
                    filteredProducts?.length && !loading ?
                        <ul className="product-list">
                          {filteredProducts.map((product, index) =>
                              <li className="product-item" key={`${product.name}-${index}`}>
                                <ProductCard product={product}/>
                              </li>)
                          }
                        </ul> :
                        <NoData title={'No Products Found!'}/>
                  }
                </div>
          }
        </Page>
      </>
  );
};

export default ProductsListPage;
