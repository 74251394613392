import React, {useRef, useState} from 'react';
import './BookServiceCall.scss';
import Button from '../../Button/Button';
import {PaperAirplaneIcon} from '@heroicons/react/24/solid';
import COUNTRIES from '../../../constants/COUNTRIES.json';
import {sendMessage} from '../../../firebase/message.store';
import {showNotification} from '../../../redux/notification.reducer';
import {useDispatch} from 'react-redux';
import DropdownSelector from '../../common/DropdownSelector';

const BookServiceCall = () => {

    const companyNameRef = useRef();
    const contactNameRef = useRef();
    const contactEmailRef = useRef();
    const contactPhoneRef = useRef();
    const scaleBrandRef = useRef();
    const scaleCapacityRef = useRef();
    const address1Ref = useRef();
    const address2Ref = useRef();
    const postalCodeRef = useRef();
    const countryRef = useRef();

    const [complain, setComplain] = useState('');

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const companyName = getValue(companyNameRef);
        const contactName = getValue(contactNameRef);
        const contactPhone = getValue(contactPhoneRef);
        const scaleBrand = getValue(scaleBrandRef);
        const scaleCapacity = getValue(scaleCapacityRef);
        const address1 = getValue(address1Ref);
        const address2 = getValue(address2Ref);
        const postalCode = getValue(postalCodeRef);
        const country = getValue(countryRef);
        const email = getValue(contactEmailRef);

        await sendMessage({
            to: {
                email: email || '',
                name: contactName || '',
                phone: contactPhone || ''
            },
            type: 'service',
            message: complain || '',
            data: {
                company: {name: companyName || ''},
                address: {
                    street: address1 || '',
                    city: address2 || '',
                    postalCode: postalCode || '',
                    country: country || ''
                },
                product: {brand: scaleBrand || '', model: scaleCapacity || ''}
            }
        });


        dispatch(showNotification({
            message: '🎉 Hooray! We\'re thrilled to inform you that your message has been successfully received! Our team is on it and will get back to you as soon as possible!',
            title: 'Message Received! We\'re on It!',
            type: 'success',
            duration: 5_000
        }));

        e.target.reset();

    };

    const getValue = (ref) => ref.current.value;

    const getCountryOptions = () => COUNTRIES.map((value, index) => ({
        value: value.name,
        label: value.name,
        key: `${value.name}-${index}`
    }));

    return (
        <>
            <section className="contact-forms">
                <h1 className={'title'}>Service Call Booking </h1>
                <h3 className={'subTitle'}>Schedule a Professional Service Call for Your Weighing Scale Maintenance and
                    Repairs</h3>
                <form onSubmit={handleSubmit}>
                    <fieldset name={'companyName'}>
                        <label htmlFor='companyName'>Company Name<sup>*</sup></label>
                        <input
                            type='text' name='companyName' id='companyName' placeholder={'What\'s your company name?'}
                            autoComplete={'organization'}
                            required={true}
                            ref={companyNameRef}
                        />
                    </fieldset>
                    <fieldset name={'contactName'}>
                        <label htmlFor='contactName'>Contact Name<sup>*</sup></label>
                        <input
                            type='text' name='contactName' id='contactName' placeholder={'Enter your name'}
                            autoComplete={'given-name family-name'}
                            required={true}
                            ref={contactNameRef}
                        />
                    </fieldset>
                    <fieldset name={'email'}>
                        <label htmlFor='email'>Email<sup>*</sup></label>
                        <input
                            type='email' name='email' id='email' placeholder={'Enter your email'}
                            autoComplete={'email'}
                            required={true}
                            ref={contactEmailRef}
                        />
                    </fieldset>
                    <fieldset name={'contactPhone'}>
                        <label htmlFor='contactPhone'>Contact Phone</label>
                        <input
                            type='tel' name='contactPhone' id='contactPhone' placeholder={'Enter your phone'}
                            autoComplete={'tel'}
                            required={false}
                            ref={contactPhoneRef}
                        />
                    </fieldset>
                    <fieldset name={'scaleBrand'}>
                        <label htmlFor='scaleBrand'>Scale Brand</label>
                        <input
                            type='text' name='scaleBrand' id='scaleBrand' placeholder={'Enter your scale brand'}
                            autoComplete={'off'}
                            required={false}
                            ref={scaleBrandRef}
                        />
                    </fieldset>
                    <fieldset name={'scaleCapacity'}>
                        <label htmlFor='scaleCapacity'>Scale Capacity</label>
                        <input
                            type='text' name='scaleCapacity' id='scaleCapacity'
                            placeholder={'Enter your scale capacity'}
                            autoComplete={'off'}
                            required={false}
                            ref={scaleCapacityRef}
                        />
                    </fieldset>
                    <fieldset name={'complain'} className={'full'}>
                        <label htmlFor='complain'>Complain</label>
                        <textarea
                            name={'complain'} id={'complain'} value={complain}
                            onChange={(e) => setComplain(e.target.value)} required={false}
                            placeholder={'Please describe the issue so we can serve you better...'}
                        ></textarea>
                    </fieldset>

                    <fieldset className='full'></fieldset>

                    <fieldset name={'streetAddress'}>
                        <label htmlFor='streetAddress'>Street Address</label>
                        <input
                            type='text' name='streetAddress' id='streetAddress' placeholder={'Street Address'}
                            autoComplete={'address-line1'}
                            required={false}
                            ref={address1Ref}
                        />
                    </fieldset>

                    <fieldset name={'city'}>
                        <label htmlFor='city'>City<sup>*</sup></label>
                        <input
                            type='text' name='city' id='city' placeholder={'City'}
                            autoComplete={'address-level2'}
                            required={true}
                            ref={address2Ref}
                        />
                    </fieldset>
                    <fieldset name={'postalCode'}>
                        <label htmlFor='postalCode'>Postal Code</label>
                        <input
                            type='text' name='postalCode' id='postalCode' placeholder={'A1A 1A1'} maxLength={7}
                            autoComplete={'postal-code'}
                            required={false}
                            ref={postalCodeRef}
                        />
                    </fieldset>
                    <fieldset name={'country'}>
                        <label htmlFor='country'>Country</label>
                        <DropdownSelector id={'country'} options={getCountryOptions()} ref={countryRef}/>
                    </fieldset>
                    <div className='action-bar right full'>
                        <Button className={'solid primary pill'} type={'submit'}>
                            Book a Service Call
                            <PaperAirplaneIcon className={'heroicon-24'}/>
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default BookServiceCall;
