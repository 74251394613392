export const HERO_CONTENT = [
  {
    title: 'Your Weighing Needs, Met.',
    subTitle: 'Explore our extensive range of scales, balances, and weighing accessories designed to meet the highest standards of accuracy and durability.',
    cta: {
      label: `Explore Products`,
      href: 'products',
      target: '_self'
    }
  },
  {
    title: 'More Than Just Scales.',
    subTitle: 'Our team of experienced technicians offers a comprehensive suite of services, including calibration, repair, and installation.',
    cta: {
      label: `Check out our Services`,
      href: 'services',
      target: '_self'
    }
  },
  {
    title: 'Connect with Weigh-Tech Solutions Today.',
    subTitle: 'Our friendly and knowledgeable team is ready to assist you. Fill out our contact form or give us a call to schedule a consultation.',
    cta: {
      label: `Contact Us Now`,
      href: 'contact',
      target: '_self'
    }
  },

];
