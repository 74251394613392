import {createSlice} from '@reduxjs/toolkit';

export const CartReducer = createSlice({
  name: 'cart',
  initialState: {
    items: {},
    notification: false
  },
  reducers: {
    setCartItems: (state, payload) => {
      state.items = payload.payload;
    },
    addItem: (state, payload) => {
      if (state.items[payload.payload.item.id]) {
        state.items[payload.payload.item.id] = {
          ...state.items[payload.payload.item.id],
          qty: state.items[payload.payload.item.id].qty
        };
      } else {
        state.items[payload.payload.item.id] = {qty: payload.payload.qty, item: payload.payload.item};
      }

      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    removeItem: (state, payload) => {
      delete state.items[payload.payload.id];
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    clearItems: (state) => {
      state.items = {};
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    updateItem: (state, payload) => {
      state.items[payload.payload.item?.id] = {...state.items[payload.payload.item?.id], qty: payload.payload.qty};
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    showCartNotification: (state) => {
      state.notification = true;
    },
    hideCartNotification: (state) => {
      state.notification = false;
    }
  }
});

export const {
  addItem,
  removeItem,
  clearItems,
  updateItem,
  setCartItems,
  showCartNotification,
  hideCartNotification
} = CartReducer.actions;

export default CartReducer.reducer;
