import React, {useEffect, useState} from 'react';
import './Contact.scss';
import {useScrollToTop} from '../../utils/scroll';
import {useParams} from 'react-router-dom';
import ContactUs from '../../components/Forms/Contact-Us/Contact-Us';
import BookServiceCall from '../../components/Forms/Book-Service-Call/Book-Service-Call';
import GetAQuoteProduct from '../../components/Forms/Get-A-Quote-Product/Get-A-Quote-Product';
import {useDispatch} from 'react-redux';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import Page from '../../components/common/Page/Page';

const Contact = () => {

  const params = useParams();
  const [id, setId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(addCrumbs([{label: 'Contact Us', link: '/contact'}]));
  }, []);

  useEffect(() => {
    const {id} = params;
    setId(id);
  }, [params]);

  useScrollToTop();

  const getComponent = () => {
    switch (id) {
      case 'book-service-call':
        return <BookServiceCall/>;
      case 'get-a-quote':
        return <GetAQuoteProduct/>;
      default:
        return <ContactUs/>;
    }
  };

  return (
      <>
        <Page>
          {
            getComponent()
          }
        </Page>
      </>
  );
};

export default Contact;
