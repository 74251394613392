import React, {useEffect} from 'react';
import './ProductsCategoryPage.scss';
import ProductsCategoryList from '../../components/ProductsCategoryList';
import {addCrumbs} from '../../redux/breadcrumb.reducer';
import {useDispatch} from 'react-redux';
import Page from '../../components/common/Page/Page';

const ProductsCategoryPage = () => {


  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(addCrumbs([{label: 'Products', link: '/products'}]));
  }, []);

  return (
      <>
        <Page title={'Precision Weighing Equipment for Every Need'}
              subTitle={'Discover a Wide Range of High-Quality Scales and Balances from Weigh-Tech\n' +
                  '              Solutions'}>
          <ProductsCategoryList/>
        </Page>
      </>
  );
};

export default ProductsCategoryPage;
