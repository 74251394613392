import React, {useEffect, useState} from 'react';
import './FeaturedProducts.scss';
import Button from '../Button/Button';
import {ChevronRightIcon} from '@heroicons/react/24/solid';
import ProductCard from '../Product-Card/Product-Card';
import {getFeaturedProductsList} from '../../firebase/products.store';
import {Link} from 'react-router-dom';

const FeaturedProducts = (props) => {

  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const products = await getFeaturedProductsList();

    setFeaturedProducts(products || []);
  };

  return (
      <>
        {
            featuredProducts.length > 0 &&
            <div className={'featured-products'}>
              <div className="content">
                <ul className="product-list">
                  {
                    featuredProducts.map((value, index) =>
                        <li className="product-item" key={`${value.name}-${index}`}>
                          <ProductCard product={value} loading={false}/>
                        </li>)
                  }
                </ul>
              </div>
              <div className="action-bar actions">
                <span className="spacer"></span>
                <Link to="products" relative={'path'}>
                <Button className={'solid primary'}>Check Out More Products <ChevronRightIcon
                    className={'heroicon-24'}/></Button>
                </Link>
              </div>
            </div>
        }
      </>
  );
};

export default FeaturedProducts;
