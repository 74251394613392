// Tab.js
import React from 'react';

const Tab = ({children}) => {
  return (
      <>
        {children}
      </>
  );
};

export default Tab;
