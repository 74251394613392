import {addDoc, collection} from 'firebase/firestore';
import {db} from './index';


const emailsCollection = collection(db, 'emails');
const messageCollection = collection(db, 'messages');
export const sendEmail = async (data) => addDoc(emailsCollection, data);

export const sendMessage = async (data) => addDoc(messageCollection, {
  ...data,
  createdAt: new Date(),
  updatedAt: new Date()
});
