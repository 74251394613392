import './App.scss';
import Navbar from './components/Navbar/Navbar';
import {Link, Outlet, useLocation} from 'react-router-dom';
import Footer from './components/Footer/Footer';
import {PhoneIcon} from '@heroicons/react/20/solid';
import Notification from './components/Notification/Notification';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import {useEffect, useRef} from 'react';

function App() {
  const location = useLocation();
  const app = useRef();

  useEffect(() => {
    document.getElementsByTagName('main')[0].scrollIntoView({behavior: 'smooth', block: 'start'});
  }, [location.pathname]);

  return (<div className="app" ref={app}>
    <Navbar/>
    <main>
      {
        location.pathname !== '/' ?
            <Breadcrumbs/> : undefined
      }
      <Outlet/>
    </main>
    <Footer/>
    <Link to={'contact/book-service-call'} className="book-service-call">
      <div className={'content'}>
        <div className="icon">
          <PhoneIcon className={'heroicon-32'}/>
        </div>
        <div className="text">Book a Service Call!</div>
      </div>
    </Link>
    <Notification/>
  </div>);
}

export default App;
